.svd_property_editor_dropdown {
  .svd-toolbar-dropdown {
    width: 100%;
  }
}

.svd_container
  .svd_content
  .svd_property_editor_dropdown
  .svd-toolbar-dropdown
  .svd_custom_select {
  width: 100%;
}

.svd_property_editor_dropdown {
  min-width: 120px;
  display: block;
}

.svd_items_table .svd_property_editor_dropdown {
  min-width: 100px;
  width: 100px;
}
