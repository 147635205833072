@import "../colors";

.svd_container {
  .svd_toolbox {
    border: none;
    font-size: 1.17em;
    margin-top: 10px;

    .panel-info {
      border: none;
      &.panel {
        background: transparent;
      }

      & > .svd-toolbox-category-header {
        border: none;
        background-color: transparent;
        padding-left: 0;
        font-size: 1.17em;
        font-weight: 600;
        cursor: pointer;
        padding: 10px 15px;

        span {
          text-decoration: none;
          text-transform: uppercase;
        }

        .icon-toolbox-arrow {
          float: right;
        }
      }

      [role="tabpanel"] {
        padding-left: 15px;
      }
    }
  }
}
