$primary-color-m: #1ab394;
$primary-text-color-m: #676a6c;
$secondary-bg-color-m: #f4f4f4;
$primary-border-color-m: #e7eaec;

.svd_container.sv_bootstrapmaterial_css {
  .panel.card {
    box-shadow: none;
  }
  .form-group {
    margin-top: 0;
  }
  .svd_custom_select .form-group,
  .svd_properties_grid .form-group {
    margin-top: 0;
    padding-bottom: 0;
    .form-control {
      margin-top: -3px;
      margin-bottom: 0;
    }
  }
  .svd_properties_grid {
    .glyphicon-edit {
      border-radius: 0;
      background-color: $primary-color-m;
      color: white;
      text-transform: uppercase;
      padding: 5px;
      font-size: 12px;

      &:hover {
        background-color: rgba(153, 153, 153, 0.2);
      }
    }
  }

  .item_editable > span {
    position: static;
    display: inline-block;
  }
  .svda-adorner-root span {
    position: static;
    display: inline-block;
    transition: none;
  }
  .modal-footer .btn + .btn {
    margin-bottom: 10px;
  }

  .svd-propertyeditor-condition {
    .btn-primary {
      margin-bottom: 20px;
    }

    .svd_custom_select {
      max-width: 165px;
    }

    textarea {
      color: $primary-text-color-m;
    }
  }

  select.svda_current_type {
    -webkit-appearance: menulist;
    -moz-appearance: menulist;
    appearance: menulist;
  }

  .svd_question {
    border: 1px solid $primary-border-color-m;
  }

  .modal-content {
    .form-group {
      .sjs-cb-wrapper {
        margin-bottom: 6px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .btn-danger {
      background-color: #f44336;
      color: $secondary-bg-color-m;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  .modal-content {
    @media (min-width: 768px) {
      .form-inline .checkbox input[type="checkbox"],
      .form-inline .radio input[type="radio"] {
        position: absolute;
        margin-left: 0;
      }

      .form-inline .checkbox span,
      .form-inline .radio span {
        top: 4px;
      }

      .form-inline .checkbox label,
      .form-inline .radio label {
        padding-left: 45px;
        margin-bottom: 10px;
      }
    }
  }
}
